import React from 'react';
import './Certficate.css';


interface CertificateProps {
  institution: string;
  img: string;
  program: string;
  length: string;
  image: string;
  date: string;
  courselink: string;
  certificate: string;
  instructors: string[];
}


const Certificate: React.FC<CertificateProps> = (

  { 
    institution, 
    img,
    program, 
    length, 
    image,
    date,
    courselink,
    certificate, 
    instructors
  }
) => {
const githubLink="https://github.com/jonathonireland/";
  return (

<div className="certficate">
      <img src={img} alt={institution} className="background" />
      <h4>{program}</h4>
      
      <div className="work-duration">{date}</div>
      <p>{length} | <a href={courselink}>Course Link</a> | <a href={certificate}>Certificate Link</a></p>
      <img src={image} alt={institution} className="certimage" />
      <p>Instructors:
      
        { 
          instructors.map(
            (instructor, i, rowcount) => (
             
              <span key={i}>{instructor} </span>
            
            )
          )
        }
      </p>
     
    </div>
  );
}

export default Certificate;
import React from 'react';
import './App.css';
import NavBar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Skills from './components/Skills/Skills';
import WorkExperience from './components/WorkExperience/WorkExperience';
import EducationExperience from './components/Education/EducationExperience';
import Certificates from './components/Certificates/Certificates';
import ContactMe from './components/ContactMe/ContactMe';
import Footer from './components/Footer/Footer';
import Gallery from './components/Gallery/Gallery';
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Jonathon Ireland | Software Engineer</title>
      </Helmet>
      <NavBar />
        <div className="container">
          <Hero />
          <Skills />
          <WorkExperience />
          <Gallery />
          <EducationExperience/>
          <Certificates/>
          <a className="skip-link" href="/" style={{fontSize: "2rem"}}>Link</a>
          <ContactMe />
        </div>
      <Footer />
    </>
  );
}

export default App;

import React from 'react';
import './ExperienceCard.css';

interface ExperienceCardProps {
  institution: string;
  title: string;
  date: string;
  img: string;
  responsibilities: string[];
}

const ExperienceCard: React.FC<ExperienceCardProps> = ({ institution, title, date, img, responsibilities }) => {
  return (
    <div className="work-experience-card">
      <img src={img} alt={institution} className="background" />
      <h5>{title}</h5>
      <h6>{institution}</h6>
      <div className="work-duration">{date}</div>
      <ul>
        {responsibilities.map((responsibility, index) => (
          <li key={index}>{responsibility}</li>
        ))}
      </ul>
    </div>
  );
}

export default ExperienceCard;
import React, { useRef } from 'react';
import './WorkExperience.css';
import Slider from 'react-slick';
import { WORK_EXPERIENCE } from '../../utils/data';
import ExperienceCard from './ExprienceCard/ExperienceCard';

const WorkExperience = () => {
  const sliderRef = useRef<Slider>(null); // Specify the type for useRef
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ]
  };

  
  

  const slideRight = () => {
    const slider = sliderRef.current;
    if (slider) {
      slider.slickNext();
    }
  };

  const slideLeft = () => {
    const slider = sliderRef.current;
    if(slider){
      slider.slickPrev();
    }
  };
  
  return (
    <section id="work-exp" className="experience-container">
      <h5>Work Experience</h5>
      <div className="experience-content">
        <div className="arrow-right" onClick={slideRight}>
          <span className="material-symbols-outlined" aria-hidden>chevron_right</span>
        </div>
        <div className="arrow-left" onClick={slideLeft}>
          <span className="material-symbols-outlined" aria-hidden>chevron_left</span>
        </div>
        <Slider ref={sliderRef} {...settings}>
          {WORK_EXPERIENCE.map((item, index) => (
            <ExperienceCard
              key={index}
              institution={item.institution}
              img={item.img}
              title={item.title}
              date={item.date}
              responsibilities={item.responsibilities}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default WorkExperience;

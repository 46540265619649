import React from "react";
import "./ContactInfoCard.css";

interface ContactInfoCardProps {
  iconUrl: string;
  link: string;
  text: string;
  className?: string;
}

const ContactInfoCard: React.FC<ContactInfoCardProps> = ({ iconUrl, link, text, className }) => {
  if (className) {
    let classConcat = 'icon ' + className;
    return (
      <div className="contact-details-card">
        <a href={link}>
          <div className={classConcat}>
            <img src={iconUrl} alt={text} />
          </div>
        </a>
        <p>{text}</p>
      </div>
    );
  } else {
    return (
      <div className="contact-details-card">
        <a href={link}>
          <div className="icon">
            <img src={iconUrl} alt={text} />
          </div>
        </a>
        <p>{text}</p>
      </div>
    );
  }
};

export default ContactInfoCard;
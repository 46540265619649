import image1 from './imgs/FootwearReleaseCalendarLearnMoreDesktop_Full_Size_w_mobile.png';
import image2 from './imgs/Steps_Full_Size_w_mobile.png';
import image3 from './imgs/Baseball-Softball-Servics-w-mobile.png';
import image4 from './imgs/Categories-Desktop.png';
import image5 from './imgs/Covelli-replication-display-panels.png';
import image6 from './imgs/Chess-App.png';
import image7 from './imgs/pythonChess-Game.png';

import mobileImage1 from './imgs/Sneaker-reservations-Image.png';
import mobileImage2 from './imgs/Steps-component-mobile.png';
import mobileImage3 from './imgs/Baseball-Softball-Services-Mobile.png';
import mobileImage4 from './imgs/categories-mobile.png';
import mobileImage5 from './imgs/mobile-display-software.png';
import mobileImage6 from './imgs/Chess-App-Mobile.png'; 
import mobileImage7 from './imgs/ChessGameMobile.png';

const WM = "Web Manager MIS/IT, Covelli Enterprises";
const SE1 = "Software Engineer, DICK'S Sporting Goods";
const CL = "Continued Learning";

const dateRange1 = "5/2012-12/2015";
const dateRange2 = "10/2018 - 9/2023";


const title1 = "Footwear Release Calendar Reservation Panel";
const link1 = "https://www.dickssportinggoods.com/s/footwear-release-calendar";
const description1 = "I took charge of numerous code revisions for the footwear release calendar and golf launch calendar at DICK'S Sporting Goods. This included managing the reservations slide-out panel across both desktop and mobile platforms. By overseeing these updates, I ensured smooth functionality and an enhanced user experience for customers accessing these important features.";
const dates1 = dateRange2;
const technologies1 = [ { technology: "Angular", percentage: 60 }, { technology: "MySQL", percentage: 20 }, { technology: "CSS3", percentage: 10 }, { technology: "Collaboration", percentage: 10 }];

const title2 = "Steps Component";
const link2 = "https://www.dickssportinggoods.com/s/best-price-guarantee";
const description2 = "At DICK'S Sporting Goods, I implemented diverse options for the Steps Component to use icons and colors, leveraging the 'Homefield' Design System based on Tailwind CSS. This involved incorporating a range of visual elements to enhance user interface design, ensuring consistency and alignment with the brand's aesthetic standards.";
const dates2 = dateRange2;
const technologies2 = [{ technology: "Angular", percentage: 60 }, { technology: "Collaboration", percentage: 20 }, { technology: "MySQL", percentage: 20 }];

const title3 = "Baseball and Softball Services";
const link3 = "https://www.dickssportinggoods.com/s/baseball-softball-services";
const description3 = "During my tenure at DICK'S Sporting Goods, I took the lead in designing CMS template layouts and collaborated closely with ECOM Production and other internal teams. Together, we revamped all store services pages, ensuring they were current, easily managed, and responsive. It was a rewarding experience to contribute to enhancing the online presence and functionality of the company's services.";
const dates3 = dateRange2;
const technologies3 = [{ technology: "Angular", percentage: 40 }, { technology: "MySQL", percentage: 20 },{ technology: "Collaboration", percentage: 40 }];

const title4 = "Categories Component";
const link4 = "https://www.publiclands.com/s/cannondale";
const description4 = "I was involved with several revisions of the category component, exploring various mobile variations. In the image here, the categories component showcases round pictures positioned above the category names, reflecting our iterative approach to optimizing the user interface.";
const dates4 = dateRange2;
const technologies4 = [{ technology: "Angular", percentage: 50 }, { technology: "CSS3", percentage: 25 }, { technology: "Colaboration", percentage: 25 }];

const title5 = "Display Screen Management Software";
const link5 = "#";
const description5 = "During my tenure at Covelli Enterprises, I spearheaded the development, engineering, and deployment of a software system solution. This system efficiently managed all promotional updates across 300+ computers utilized for displaying seasonal promotions in over 200 Panera Bread Cafés. It was a pivotal project that streamlined operations and ensured consistent and timely updates throughout the network.";
const dates5 = dateRange1;
const technologies5 = [{ technology: "PHP", percentage: 40 }, { technology: "MySQL", percentage: 45 }, { technology: "JavaScript", percentage: 5 }, { technology: "Apache", percentage: 5 }, {technology: "Linux", percentage: 5}];

const title6 = "Chess Web App";
const link6 = "https://jonathonireland.com/chess-app/";
const description6 = "I've been thoroughly enjoying developing my React Chess App—it's been both a learning experience and a source of fun. In the pipeline, I have plans to deploy a Flask API to interface with a database, enabling the recording of moves for each game. This project has been a great way to practice React development while also laying the groundwork for future enhancements.";
const dates6 = "2/2024 - 3/2024";
const technologies6 = [{ technology: "React", percentage: 45 }, { technology: "Typescript", percentage: 45 }, { technology: "CSS3", percentage: 5 },{ technology: "HTML5", percentage: 5 }];

const title7 = "Computer Chess Game";
const link7 = "https://github.com/jonathonireland/pythonChess";
const description7 = "Creating my first non-web app with Python was a delightful journey. I found joy in learning Python and didn't stop at just building the game—I kept adding features and eventually implemented persistent data storage. This project not only enhanced my technical skills but also sparked my passion for innovation and continuous improvement.";
const dates7 = "12/2023 - 1/2024";
const technologies7 = [{ technology: "Python", percentage: 85 }, { technology: "MySQL", percentage: 15 } ];

// Putting it all together
export const IMAGES = [image1, image2, image3, image4, image5, image6, image7];
export const MOBILE_IMAGES = [mobileImage1, mobileImage2, mobileImage3, mobileImage4, mobileImage5, mobileImage6, mobileImage7];
export const TITLES = [title1, title2, title3, title4, title5, title6, title7];
export const INITIATIVES = [SE1, SE1, SE1, SE1, WM, CL, CL];
export const DESCRIPTIONS = [description1, description2, description3, description4, description5, description6, description7];
export const DATES = [dates1, dates2, dates3, dates4, dates5, dates6, dates7];
export const LINKS = [link1, link2, link3, link4, link5, link6, link7];
export const TECHNOLOGIES: [string, number][][] = [
  technologies1.map(({ technology, percentage }) => [technology, percentage]),
  technologies2.map(({ technology, percentage }) => [technology, percentage]),
  technologies3.map(({ technology, percentage }) => [technology, percentage]),
  technologies4.map(({ technology, percentage }) => [technology, percentage]),
  technologies5.map(({ technology, percentage }) => [technology, percentage]),
  technologies6.map(({ technology, percentage }) => [technology, percentage]),
  technologies7.map(({ technology, percentage }) => [technology, percentage])
];

import React, { useRef } from 'react';
import './Certificates.css';
import Slider from 'react-slick';
import { CERTIFICATIONS } from '../../utils/data';
import Certficate from './Certificate/Certficate';

const Certificates = () => {
  const sliderRef = useRef<Slider>(null); // Specify the type for useRef
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ]
  };

  const slideRight = () => {
    const slider = sliderRef.current;
    if (slider) {
      slider.slickNext();
    }
  };

  const slideLeft = () => {
    const slider = sliderRef.current;
    if(slider){
      slider.slickPrev();
    }
  };
  
  return (
    <section id="certificates" className="certifcates-container">
      <h5>Certificates</h5>
      <div className="certficates-content">
        <div className="arrow-right" onClick={slideRight}>
          <span className="material-symbols-outlined" aria-hidden>chevron_right</span>
        </div>
        <div className="arrow-left" onClick={slideLeft}>
          <span className="material-symbols-outlined" aria-hidden>chevron_left</span>
        </div>
        <Slider ref={sliderRef} {...settings}>
          {CERTIFICATIONS.map((item, index) => (
            <Certficate
              key={index}
              institution={item.institution} 
              img={item.img}
              program={item.program}
              length={item.length}
              courselink={item.courselink}
              certificate={item.certificate}
              image={item.image}
              date={item.date}
              instructors={item.instructors}
            />
          ))}
        </Slider> 
      </div>
    </section>
  );
}

export default Certificates;

import React from 'react';
import './EducationCard.css';

interface EducationCardProps {
  institution: string;
  program: string;
  link: string;
  major: string;
  note: string;
  date: string;
  img: string;
  focus: string[];
}

const EducationCard: React.FC<EducationCardProps> = (
  { 
    institution, 
    program, 
    link,
    major, 
    note,
    date,
    img,
    focus
  }
) => {
  return (
    <div className="education-card">
      <img src={img} alt={institution} className="background" />
      <h4>{institution}</h4>
      <h5>{program}</h5>
      <h6><a href={link}>{major}</a></h6>
      <p>{note}</p>
      <div className="work-duration">{date}</div>
      <ul>
        {focus.map((focus, index) => (
          <li key={index}>{focus}</li>
        ))}
      </ul>
    </div>
  );
}

export default EducationCard;
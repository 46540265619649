export const SKILLS = [
  {
    title: "Frontend",
    icon: "./assets/images/code.png",
    skills: [
      { skill: "HTML5", percentage: "90%" },
      { skill: "CSS3 (Sass/SCSS)", percentage: "90%" },
      { skill: "JavaScript", percentage: "90%" },
      { skill: "React.js", percentage: "70%" },
      { skill: "Angular", percentage: "80%" },
      { skill: "Typescript", percentage: "70%" },
    ],
  },
  {
    title: "Backend",
    icon: "./assets/images/database.png",
    skills: [
      { skill: "MySQL", percentage: "70%" },
      { skill: "Python", percentage: "40%" },
      { skill: "PHP", percentage: "50%" },
      { skill: "C#", percentage: "30%"},
    ],
  },
  {
    title: "Tools",
    icon: "./assets/images/vernier-caliper.png",
    skills: [
      { skill: "Git & GitHub", percentage: "85%" },
      { skill: "Visual Studio Code", percentage: "75%" },
      { skill: "Figma", percentage: "60%" },
      { skill: "Responsive Design", percentage: "70%" },
      { skill: "Visual Studio 2022", percentage: "30%" },
    ],
  },
  {
    title: "Soft Skills",
    icon: "./assets/images/leadership.png",
    skills: [
      { skill: "Problem-solving", percentage: "80%" },
      { skill: "Collaboration", percentage: "85%" },
      { skill: "Attention to Detail", percentage: "75%" },
      { skill: "Linux", percentage: "40%"},
      { skill: "Windows", percentage: "30%"},
      { skill: "Mac", percentage: "95%"}
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    institution:"Equifax Workforce Solutions",
    title: "Software Engineer - Full Time Contract",
    date: "August 2024 - Present",
    img: "./assets/images/Equifax.png",
    responsibilities: [
      "Node application maintenance & enhancements",
      "Angular application maintenance & enhancements",
      "CI/CD Jenkins & ArgoCD Pipelines",
      "Kubernetes deployment management",
      "Secrets Manager integrations",
      "Git Version Control",
      "Code reviews & Pull requests",
      "Development in AWS Cloud environments.",
      "JavaScript & Typescript development.",
      "Agile method of working, daily standups, "
    ],
  },
  {
    institution:"DICK'S Sporting Goods",
    title: "Software Engineer - Full Time Employee",
    date: "November 2018 - September 2023",
    img: "./assets/images/DICKS-LOGO.png",
    responsibilities: [
      "Angular, ReactJS, and AEM component development. Spoof tested and deployed Akamai updates. Gave timely support to business for reported bugs.",
      "2 years experience in AEM Components in AEM Sling Model (Java classes) to display data in the UI. Built 4 different components in 4 different•	Developed 4 different AEM components in 4 different sprints using AEM Sling Model (Java classes) to display data in the UI, 2 of these components utilized React.js to display data.",
      "Independently led a cross-team process-improvement initiative, working with multiple stakeholders across technology and non-technology teams.",
      "Refined, documented, and championed new processes to improve data quality, reduce errors, eliminate redundancy, closed communication gaps.",
      "Promoted code via GitHub Action Workflows and Pivotal Cloud Foundry pipelines.",
      "Worked with Node.js, Node Version Manager, Node Package Manager, and Node Package Manager Exec.",
      "Docker used for “HomR” (DKS’s own open source derived CMS Full Stack Development).",
      "Subject matter expert of CMS static and home page templates for Angular components creation with 94 templates created of 241 by the end of my tenure."
    ],
  },
  {
    institution: "DICK'S Sporting Goods",
    title: "Front End Developer - Full Time Employee",
    date: "September 2017 - October 2018",
    img: "./assets/images/DICKS-LOGO.png",
    responsibilities: [
      "Developed and maintained client wDevelopment of web pages on E-commerce platform (IBM WebSphere Management Center). ",
      "Working with JavaScript, CSS, HTML5, Git, SCSS, meeting responsive design specs. ebsites, ensuring high-quality standards and timely project delivery.",
      "Managed all grand opening web page development.",
      "Conducted code reviews and mentored junior developers in adopting best coding practices.",
    ],
  },
  {
    institution: "Kent State University",
    title: "Associate Application Developer - Full Time Employee",
    date: "December 2015 - March 2017",
    img: "./assets/images/Kent-State-Wide.png",
    responsibilities: [
      "Managed PHP / MySQL / Laravel web applications and updates for the Information Services Department and University Blackboard Web Application.",
      "Reprogrammed the university web application “Presenter” into the new PHP Laravel framework.",
      "Presenter converted PowerPoint files into web-shareable slideshows for teachers, staff, and students.",
      "Focused on transitioning the application from legacy PHP code to new server with a Laravel foundation. Utilizing tools and technologies such as Artisan migrations and CLI, Middleware, Views, Eloquent ORM, Routing, Elixir, Gulp and Vue.js.",
      "Managed the Red Hat Enterprise Linux Server for the new “Presenter” application.",
    ],
  },
  {
    institution: "Covelli Enterprises",
    title: "Web Manager in MIS/IT - Full Time Employee",
    date: "May 2012 - December 2015",
    img: "./assets/images/Covelli-Enterprises.png",
    responsibilities: [
      "Coded, engineered all web related responsibilities, managed company webhost.",
      "Managed company email marketing and email subsription databases.",
      "Managed WordPress Websites for Panerathon.org, Covelli.com, and AWLRescueMe.org",
      "Managed Company digital sign updates.",
      "Managed Company CENTOS Web Server with CPANEL and Linux CLI",
      "Conceptualized, Engineered, Programmed, and deployed a custom software application to 300+ Computers. Converting them from PowerPoint presentations to using JavaScript Slideshows powered by a PHP web application with MySQL replication as the foundation.",
    ],
  },
  {
    institution: "Brain Host",
    title: "Front End Web Developer - Full Time Employee",
    date: "November 2011 - March 2012",
    img: "./assets/images/image-not-available.png",
    responsibilities: [
      "Created HTML pages based on Photoshop mockup illustrations.",
      "WordPress and Codeigniter Website maintenance.",
      "Helped design website templates for their ‘website builder’ software they were developing.",
    ],
  },
  {
    institution: "Carnegie Mellon University",
    title: "Web Technology Production Assistant - Full Time Employee",
    date: "February 2011 - June 2011",
    img: "./assets/images/Carnegie-Mellon-University.png",
    responsibilities: [
      "Facilitated production of HTML / CSS / JavaScript and content on blackboard support page of their website.",
    ],
  },
  {
    institution: "Vindy.com (Vindicator Printing Company)",
    title: "Online Content Production Specialist - Full Time Employee",
    date: "April 2007 - January 2011",
    img: "./assets/images/Vindy-com-logo.png",
    responsibilities: [
      "Front end code creator in the interactive department, programming the user interface of Vindy.com and it’s sister websites.",
      "Django based template website changes built on Ellington CMS.",
      "Re-designed Vindy.com 2 times through my time at the Vindicator.",
    ],
  }
];

export const EDUCATION = [
  {
    institution: "Purdue University / Edureka",
    program: "Postgraduate",
    major: "DevOps Program",
    note: "*Instructor-Led Courses Scheduled Fridays & Saturdays 10:30P-1:30A EST ",
    link: "https://www.edureka.co/executive-programs/purdue-devops?utm_source=hometrending&utm_campaign=trendingcourses",
    date: "April 2024 - January 2025",
    img: "./assets/images/inst_img_2299_1683021906.png",
    focus: [
      "Linux Fundamentals Certification Training, Self Paced",
      "Python Programming Certification, Self Paced",
      "DevOps Fundamentals, Instructor Led, April 26",
      "GIT Source Code Management, Instructor Led, May 17",
      "Jenkins Continuous Integration, Instructor Led, June 21",
      "Ansible Configuration Management, Instructor Led, July 26",
      "Continuous Deployment using Docker, Instructor Led, August 30",
      "Kubernetes Container Orchestration, Instructor Led, October 4",
      "AWS Continuous Monitoring and DevOps, Instructor Led, November 15",
      "Industry Grade Project, Instructor Led, December 20",
      "Bonus Course: DevSecOps Engineering (Self-paced)",
    ],
  },
  {
    institution: "Kent State University",
    program: "Bachelors of Science",
    major: "School of Technology",
    note: "*Noteable Courses",
    link: "https://www.kent.edu/",
    date: "September 2001 - May 2006",
    img: "./assets/images/Kent-State-Wide.png",
    focus: [
      "JAVA PROGRAMMING, Spring 2005",
      "BUSINESS & PROFESSIONAL WRITING, Spring 2005",
      "WORLD POLITICS, Spring 2005",
      "ST. DREAMWEAVER/FLASH, Spring 2005",
      "ST. FRONT PAGE, Summer 2005",
      "ST. TECHNOLOGY & HUMANITIES, Fall 2005",
      "PRINCIPALS OF THINKING, Fall 2005",
      "ST. CRYPTOGRAPHY 1, Fall 2005",
      "LAN SECURITY FUNDAMENTALS, Fall 2005",
      "COMPUTER FORENSICS, Spring 2006",
      "EARTH DYNAMICS, Spring 2006",
      "EARTH HISTORY, Spring 2006"
    ],
  },
  {
    institution: "Kent State University",
    program: "Associate of Applied Business",
    major: "Network Technology",
    note: "*Noteable Courses",
    link: "https://www.kent.edu/",
    date: "September 2001 - December 2004",
    img: "./assets/images/Kent-State-Wide.png",
    focus: [
      "Survey of Information Tech, Spring 2002",
      "Computer Assembly & Config, Fall 2002",
      "Network Setup & Config, Fall 2002",
      "ST. CISCO SYSTEMS I, Fall 2002",
      "ST. INTERNETWORKING II, Spring 2003",
      "Tech of Operating Systems, Spring 2003",
      "Technology of Networking, Spring 2003",
      "Visual Basic Programming, Fall 2003",
      "Intro To Technical Writing, Fall 2003",
      "COOPERATIVE EDUCATION-WRITING INTENSIVE, Fall 2003",
      "ST. INTERNETWORKING-ADV ROUTING, Fall 2003",
      "WRKGRPO PROD SOFTWARE, Fall 2003"
    ],
  }
];

export const CERTIFICATIONS = [
  {
    institution: "Udemy.com",
    img:"./assets/images/4373284_angular_logo_logos_icon.svg",
    program: "Angular Testing Masterclass (Angular 18)",
    length: "5 Hours",
    image: "./assets/images/UC-5bcfc75f-5241-4c62-8f32-ccaea9df5b72.jpg",
    date: "July 29, 2024",
    courselink: "https://www.udemy.com/course/angular-testing-course/?couponCode=KEEPLEARNING",
    certificate: "https://www.udemy.com/certificate/UC-5bcfc75f-5241-4c62-8f32-ccaea9df5b72/",
    instructors: [
      "Vasco",
      "Angular University",
    ],
  },
  {
    institution: "Udemy.com",
    img:"./assets/images/4373284_angular_logo_logos_icon.svg",
    program: "Angular Deep Dive - Beginner to Advanced (Angular 18)",
    length: "12 Hours",
    image: "./assets/images/UC-2612f443-5657-4dff-92c1-e9f5f604eb19.jpg",
    date: "July 15, 2024",
    courselink: "https://www.udemy.com/course/angular-course/?couponCode=THANKSLEARNER24",
    certificate: "https://www.udemy.com/certificate/UC-2612f443-5657-4dff-92c1-e9f5f604eb19/",
    instructors: [
      "Vasco",
      "Angular University",
    ],
  },
  {
    institution: "Edureka.co",
    img:"./assets/images/linux.svg",
    program: "Linux Fundamentals Certification Training",
    length: "25 Hours",
    image: "./assets/images/edureka-certificate-linux-funcamentals-june-20-2024.png",
    date: "June 20, 2024",
    courselink: "https://www.edureka.co/linux-fundamentals-self-paced",
    certificate: "https://www.edureka.co/certificates/mycertificate/615e29682bff0219c2f009523b24c357",
    instructors: [
      "Team Edureka",
    ],
  },
  {
    institution: "Udemy.com",
    img:"./assets/images/Logo_C_sharp.svg.png",
    program: "C# Console and Windows Forms Development with Entity Framework",
    length: "22.5 Hours",
    image: "./assets/images/UC-cd937c58-4aad-4a4f-8e8f-9beec908a809.jpg",
    date: "June 11, 2024",
    courselink: "https://www.udemy.com/course/c-console-and-windows-forms-development-with-linq-adonet/?couponCode=ST21MT61124",
    certificate: "https://www.udemy.com/certificate/UC-cd937c58-4aad-4a4f-8e8f-9beec908a809/",
    instructors: [
      "Trevoir Williams",
      "Learn IT University",
      "Andrii Piatakha",
    ],
  },
  {
    institution: "Udemy.com",
    img:"./assets/images/1012821_code_development_logo_mysql_icon.svg",
    program: "The Ultimate MySQL Bootcamp: Go from SQL Beginner to Expert",
    length: "37.5 Hours",
    image: "./assets/images/UC-640e3bd4-cd3d-492d-bb14-a29f4fecd98f.jpg",
    date: "September 30, 2023",
    courselink: "https://www.udemy.com/course/the-ultimate-mysql-bootcamp-go-from-sql-beginner-to-expert/?couponCode=ST21MT61124",
    certificate: "https://www.udemy.com/certificate/UC-640e3bd4-cd3d-492d-bb14-a29f4fecd98f/",
    instructors: [
      "Colt Steele"
    ],
  },
];

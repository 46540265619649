import React from 'react';
import './Gallery.css';
import { ImageSlider } from './ImageSlider/ImageSlider';
import { IMAGES, MOBILE_IMAGES, TITLES, INITIATIVES, DATES, DESCRIPTIONS, LINKS, TECHNOLOGIES } from './GalleryConstants';

const Gallery = () => { 

  return (
    <section id="gallery" className="gallery-container">
      <h5>Example Work Gallery</h5>
      <ImageSlider imageUrls={IMAGES} mobileImageUrls={MOBILE_IMAGES} titles={TITLES} initiatives={INITIATIVES} dates={DATES} descriptions={DESCRIPTIONS} links={LINKS} technologies={TECHNOLOGIES} />
    </section>
  );
}

export default Gallery;
import React, { useState } from 'react';
import { Link } from "react-scroll";
import './Navbar.css';
import MobileNav from './MobileNav/MobileNav';

const NavBar = () => { 
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <>
      <MobileNav isOpen={openMenu} toggleMenu={toggleMenu}/>
      <nav className="nav-wrapper">
        <div className="nav-content">
          <img className="logo" src="./assets/images/logo.png" alt="Jonathon Ireland | Software Engineer" />
          <ul>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-80}
                to="hero">
                  Home
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-120}
                to="skills">
                Skills
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-100}
                to="work-exp">
                  Work Experience
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-100}
                to="gallery">
                  Gallery
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-100}
                to="education">
                  Education
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-100}
                to="certificates">
                  Certificates
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                activeClass="active"
                smooth spy offset={-100}
                to="contact">
                  Contact Me
              </Link>
            </li>
          </ul>
          <button className="menu-btn" onClick={toggleMenu}>
            <span
              className={"material-symbols-outlined"}
              style={{ fontSize: "1.8rem" }}>
              {openMenu ? "close": "menu"}
              </span>
          </button>
        </div>
      </nav>
    </>
  )
}
export default NavBar;
import { useState } from "react";

import './ImageSlider.css';


type ImageSliderProps = {
  imageUrls: string[],
  mobileImageUrls: string[],
  titles: string[],
  initiatives: string[],
  descriptions: string[],
  dates: string[],
  links: string[],
  technologies: [string, number][][]
}

export function ImageSlider(
  {
    imageUrls,
    mobileImageUrls,
    titles,
    initiatives,
    descriptions,
    dates,
    links,
    technologies
  }: ImageSliderProps) {

  const [imageIndex, setImageIndex] = useState(0);

  function showNextImage() { 
    setImageIndex(index => {
      if (index === imageUrls.length - 1) return 0
      return index + 1
    })
  }

  function showPrevImage() {
    setImageIndex(index => {
      if (index === 0) return imageUrls.length - 1
      return index - 1
    })
  }

  return (
    <section
      aria-label="Image Slider"
      className="img-slider">
      <a href="after-image-slider-controls" className="skip-link">Skip Image Slider</a>
      <div className="img-container">
        {imageUrls.map((url, index) => (
          <div key={index} style={{ display: index === imageIndex ? 'block' : 'none' }}>
            <picture>
             <source
              srcSet={mobileImageUrls[imageIndex]}
              sizes="max-width:768px"
              media="(orientation: portrait)"
             />
             <img
              src={url}
              aria-hidden={imageIndex !== index}  
              className="img-slider-img"
              alt={`Slide ${index} ${titles[imageIndex]}`}
             />
            </picture>
            <p className="details">
            <strong>{titles[imageIndex]}</strong><br/>
              <aside>{initiatives[imageIndex]} {dates[imageIndex]}</aside>
                Tech Stack: {technologies[imageIndex].map(([technology, percentage], index) => (
                  <span key={index}>{technology} ({percentage}%) {index !== technologies[imageIndex].length - 1 && ", "}</span>
                ))} <br />
              
              <a target="new" href={links[imageIndex]} className="visitLink">Visit Link <span className="material-symbols-outlined" aria-hidden>arrow_outward</span></a>
            </p>
          </div>
            
          ))}
      </div>
      <button
        onClick={showPrevImage}
        className="img-slider-btn img-slider-btn-left"
        aria-lable="View Previous Image"
      >
        <span className="material-symbols-outlined" style={{ fontSize: 31}}>chevron_left</span>
      </button>
      <button
        onClick={showNextImage}
        className="img-slider-btn img-slider-btn-right"
        aria-lable="View Next Image"
      >
        <span className="material-symbols-outlined" style={{ fontSize: 31}}>chevron_right</span>
      </button>
      <div style={{
        position: "absolute",
        bottom: "-1rem",
        left: "50%",
        translate: "-50%",
        display: "flex",
        gap: ".25rem",
        zIndex: "4"
      }}>
        {imageUrls.map((_, index) => (
          <button
            key={index}
            aria-label={`View Image ${index}`}
            className="img-slider-dot-btn"
            onClick={() => setImageIndex(index)}>{
            index === imageIndex ? <span className="material-symbols-outlined" aria-hidden>radio_button_checked</span> : <span className="material-symbols-outlined" aria-hidden>radio_button_unchecked</span> 
          }</button>
        ))}
      </div>
      <div id="after-image-slider-controls"/>
    </section>
  )
  
}
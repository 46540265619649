import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import "./ContactForm.css";

const ContactForm: React.FC = () => {
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const serviceId = 'service_si1mu97';
    const templateId = 'template_3jatitt';
    const publicKey = 'zAlgm-yu1pwKAyQnu';

    const templateParams = {
      from_name: firstName + ' ' + lastName,
      from_email: email,
      to_name: 'JonathonIreland.com contact',
      message: message,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setFirstName('');
        setLastName('');
        setMessage('');
        setEmail('');
      }).catch((error) => {
        console.log('Error sending email');
        console.log(error);
      });
  
  };

  
  return (
    <div className="contact-form-content">
      <form onSubmit={handleSubmit} className='emailForm'>
        <div className="name-container">
          <input
            type="text"
            name="firstname"
            placeholder="First Name"
            value={firstName}
            onChange={(e)=>setFirstName(e.target.value)}
          />
          <input
            type="text"
            name="lastname"
            placeholder="Last Name"
            value={lastName}
            onChange={(e)=>setLastName(e.target.value)}
          />            
        </div>
        <input
          type="text"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e)=>setEmail(e.target.value)}
        />
        <textarea
          name="message"
          placeholder="Message"
          rows={3}
          value={message}
          onChange={(e)=>setMessage(e.target.value)}
        />
        <button className="contact-btn" type="submit">SEND</button>
      </form>
    </div>
  );
};

export default ContactForm;

import React from "react";
import "./ContactMe.css";
import ContactInfoCard from "./ContactInfoCard/ContactInfoCard";
import ContactForm from "./ContactForm/ContactForm";

const ContactMe: React.FC = () => {
  return (
    <section id="contact" className="contact-container">
      <h5>Contact Me</h5>

      <div className="contact-content">
        <div style={{ flex: 1 }}>

          <ContactInfoCard
            iconUrl="./assets/images/email-icon.svg"
            link="mailto:admin@jonathonireland.com"
            text="admin@jonathonireland.com"
          />

          <ContactInfoCard
            iconUrl="./assets/images/icons8-github-96.png"
            link="https://github.com/jonathonireland"
            text="github.com/jonathonireland"
          />

          <ContactInfoCard
            iconUrl="./assets/images/iconmonstr-linkedin-3.svg"
            link="https://www.linkedin.com/in/jonathonireland/"
            text="linkedin.com/in/jonathonireland"
            className="white"
          />
        </div>

        <div style={{ flex: 1 }}>
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactMe;
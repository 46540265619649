import React from "react";
import "./MobileNav.css";

const MobileNav = ({ isOpen, toggleMenu }: { isOpen: boolean, toggleMenu: () => void }) => {

  const handleScroll = (sectionId: string) => {
    if (isOpen) toggleMenu();
    const getSectiontId = document.getElementById(sectionId);
    if (getSectiontId) {
      getSectiontId.scrollIntoView({ behavior: "smooth" });
    };
  };


  return<>
    <div
      className={`mobile-menu ${isOpen ? "active" : ""}`}
      onClick={toggleMenu}
    >
      <div className="mobile-menu-container">
        <img className="logo" src="./assets/images/3.png" />
        <ul>
            <li>
              <a onClick={() => handleScroll("hero")} className="menu-item">
                Home
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("skills")} className="menu-item">
                Skills
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("work-exp")} className="menu-item">
                Work Experience
              </a>
            </li>
          
            <li>
              <a onClick={() => handleScroll("gallery")} className="menu-item">
                Gallery
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("education")} className="menu-item">
                Education
              </a>
            </li>

            <li>
              <a onClick={() => handleScroll("certificates")} className="menu-item">
                Certificates
              </a>
            </li>


            <li>
              <a onClick={() => handleScroll("contact")} className="menu-item">
                Contact Me
              </a>
            </li>
            <li>
              <a href="./chess-app" className="menu-item">
                Chess App
              </a>
            </li>
          </ul>
      </div>
    </div>
  </>;
};
export default MobileNav;

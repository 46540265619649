import React from 'react';
import './Hero.css';

const Hero = () => { 
  return (
    <section id="hero" className="hero-container">
      <div className="hero-content">
        <h2>
          Building Responsive Websites That Inspire
        </h2>
        <p>
          Passionate Developer transforming ideas into applications.
        </p>
      </div>
      <div className="hero-img">
        <div>
          
          <div className="tech-icon">
            <img src="./assets/images/img01.png" alt="React" />
          </div>
          <img src="./assets/images/A11C5F45-0586-4EAD-917D-53C866FAF69E_small.png" alt="Jonathon Ireland" />
        </div>
        <div>
          <div className="tech-icon">
            <img src="./assets/images/Logo_C_sharp.svg.png" alt="C#" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/11120662_fi_brands_typescript_icon.svg" alt="Typescript" className="white-bg" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img03.png" alt="CSS3" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/img04.png" alt="JavaScript" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/4373284_angular_logo_logos_icon.svg" alt="Angular" />
          </div>
          <div className="tech-icon">
            <img src="./assets/images/4375050_logo_python_icon.svg" alt="Python" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;